<template>
  <div class="page-b2b-order-detail refund-container">
    <div class="mod-group">
      <div class="cell-body">
        <a-steps :current="current">
          <a-step title="选择退票旅客" />
          <a-step title="计算退票费用" />
          <a-step title="申请完成" />
        </a-steps>
      </div>
    </div>
    <template v-if="current === 0">
      <RefundCheck
        :order="orderDetail"
        @valid="onRefundChecked"
        @prev="handlePrev"
        @next="handleNext"
      />
    </template>
    <template v-if="current === 1">
      <RefundFee
        :params="params"
        :order="orderDetail"
        @prev="handlePrev"
        @next="handleNext"
      />
    </template>
    <template v-if="current === 2">
      <RefundSuccess
        :params="params"
        :order="orderDetail"
        :result="refundResult"
      />
    </template>
    </div>
</template>

<script>
import queryString from 'query-string'
import { fetchOrderDetail } from '@/api/order'

import RefundCheck from './components/refundCheck'
import RefundFee from './components/refundFee'
import RefundSuccess from './components/refundSuccess'

export default {
  name: 'orderRefund',
  components: {
    RefundCheck,
    RefundFee,
    RefundSuccess
  },
  data () {
    return {
      current: 0,
      orderDetail: {},
      params: {},
      refundResult: null
    }
  },
  computed: {
    userId () {
      const userData = this.$store.state.userData
      return userData && userData.id
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.fetchDetail(params.orderNo, this.userId)
  },
  methods: {
    onRefundChecked (params) {
      this.params = params
    },
    async fetchDetail (orderNo, userId) {
      this.loading = true

      try {
        const data = await fetchOrderDetail({
          orderNo: orderNo,
          userId
        })

        this.orderDetail = data.bizData
        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    handlePrev () {
      if (this.current === 0) {
        this.$router.back()
      } else {
        this.current--
      }
    },
    handleNext (result) {
      this.current++

      if (result) {
        this.refundResult = result
      }
    }
  }
}
</script>

<style lang="less">
.refund-container {
  .refund-action-row {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
