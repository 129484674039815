<template>
  <div>
    <div class="mod-group">
      <div class="cell-header">
        <h4>计算退票费用</h4>
      </div>
      <div class="cell-body">
        <a-descriptions :column="4" layout="horizontal">
          <a-descriptions-item label="退票类型">
            {{ params && params.refundTypeCn }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="4" layout="horizontal">
          <a-descriptions-item label="团队出票量">
            {{ order.totalLeftGroupQty }}
          </a-descriptions-item>
          <a-descriptions-item label="退票后团队剩余人数">
            {{ refundData.totalLeftGroupQty }}
          </a-descriptions-item>
          <a-descriptions-item label="政策要求最低人数">
            {{ order.minGroupQty }}
          </a-descriptions-item>
          <a-descriptions-item label="退票后，剩余人员占比整团人数">
            {{(refundData.totalLeftGroupQty / order.totalLeftGroupQty) * 100 | rate }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <div class="mod-group">
      <div class="cell-header">
        <h4>退票信息</h4>
      </div>
      <div class="cell-body">
        <div :style="{color: 'red', marginBottom: '12px'}">
          如您在团队网为旅客购买了保险，请到保险订单页面进行退保操作。
        </div>
        <a-table
          :columns="refendFeeColumns"
          :data-source="refendFeeData"
          :rowKey="(record,index)=>{return index}"
          :pagination="false"
          :loading="loading"
          bordered
        >
          <template slot="index" slot-scope="index, row, idx">
              {{ idx + 1 }}
          </template>
          <template slot="discount" slot-scope="text">
            {{ text ? `${text}%` : '-' }}
          </template>
        </a-table>
      </div>
    </div>
    <div class="mod-group">
      <div class="cell-header">
        <h4>备注</h4>
      </div>
      <div class="cell-body">
        <div class="refund-notice flow">
          <p>
            1、如您团队旅客在不同子订单里，申请全团退票时，请在备注栏中填写
            申请整团退票，相关的子订单号
          </p>
          <p>
            2、实际退款金额以航空公司的审核为准。退款审批完成之后，航空公司会在1-4个工作日内（节假日顺延）
            把钱返回到您选择的支付方式（第三方支付账户/储值卡账户）中，具体到账时间由系统的处理时间决定。
            如需查看该订单的支付记录 。
          </p>
        </div>
      </div>
    </div>
    <div class="mod-group">
      <div class="cell-body">
        <div class="refund-action-row">
          <a-button @click="handlePrev"> 返回 </a-button>
          <a-button
            type="primary"
            :style="{ marginLeft: '8px' }"
            @click="handleNext"
            :loading="submitLoading"
          >
            下一步
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderRefundFee, orderRefundApplyFlow } from '@/api/order'

import _ from 'lodash'

const refendFeeColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '姓名',
    dataIndex: 'passengerName'
  },
  {
    title: '票号',
    dataIndex: 'ticketNo'
  },
  {
    title: '机票价格',
    dataIndex: 'netFare'
  },
  {
    title: '折扣率',
    dataIndex: 'discount',
    scopedSlots: { customRender: 'discount' }
  },
  {
    title: '机票退款',
    dataIndex: 'netFareRefundAmount'
  },
  {
    title: '手续费',
    dataIndex: 'serviceCharge'
  },
  {
    title: '燃油费',
    dataIndex: 'fuelFare'
  },
  {
    title: '基建费',
    dataIndex: 'airportFare'
  },
  {
    title: '航线',
    dataIndex: 'segment'
  },
  {
    title: '退票航线',
    dataIndex: 'refundSegment'
  }
]

export default {
  props: {
    params: Object,
    order: Object
  },
  data () {
    return {
      refundType: '',
      loading: false,
      refendFeeColumns,
      refendFeeData: [],
      refundData: null,
      submitLoading: false
    }
  },
  computed: {
    uniRefundFeeData () {
      return _.uniqBy(this.refendFeeData, (item) => {
        return item.passengerName + item.ticketNo
      })
    },
    refundQtyDesc () {
      const restNum = (this.order.totalLeftGroupQty - (this.order.totalRefundGroupQty || 0) - this.uniRefundFeeData.length)
      return `团队出票量: ${this.order.totalLeftGroupQty},` +
      `退票后团队剩余人数: ${restNum},` +
      `政策要求最低人数: ${this.order.minGroupQty},` +
      `退票后，剩余人员占比整团人数: ${((restNum / this.order.totalLeftGroupQty) * 100).toFixed(2)}%`
    }
  },
  created () {
    this.fetchRefundFee()
  },
  methods: {
    async fetchRefundFee () {
      this.loading = true

      try {
        const result = await orderRefundFee(this.params)

        this.refundData = result

        if (result) {
          this.loading = false
          this.freshRefundFee(result.refundPaxSegDataDTOS)
        }
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    freshRefundFee (DTOs) {
      this.refendFeeData = DTOs.map((data) => {
        const { orderItemPaxSegTaxDataDTOs } = data

        return {
          ...data,
          ...{
            fuelFare: orderItemPaxSegTaxDataDTOs ? orderItemPaxSegTaxDataDTOs.filter((item) => { return item.taxCode === 'YQ' })[0].amount : '-',
            airportFare: orderItemPaxSegTaxDataDTOs ? orderItemPaxSegTaxDataDTOs.filter((item) => { return item.taxCode === 'CN' })[0].amount : '-',
            ticketNo: data.issCode + '-' + data.ticketNo,
            segment: this.$getAirCity(data.depCode) + '-' + this.$getAirCity(data.arrCode),
            refundSegment: this.$getAirCity(data.depCode) + '-' + this.$getAirCity(data.arrCode),
            discount: this.getDiscount(data.depCode, data.arrCode)
          }
        }
      })
    },
    getDiscount (depCode, arrCode) {
      return this.order.segments.find((item) => {
        return item.depCode === depCode && item.arrCode === arrCode
      }).discount
    },
    handlePrev () {
      this.$emit('prev')
    },
    handleNext () {
      const self = this

      this.$confirm({
        title: '温馨提示',
        content: '确认发起退票申请？提交退票申请后，系统将立即释放PNR清位，请仔细核对后确认操作。',
        okText: '提交退票',
        onOk () {
          self.applyRefund()
        },
        cancelText: '取消',
        onCancel () {}
      })
    },
    async applyRefund () {
      this.submitLoading = true

      const propsParams = Object.assign({}, this.params)

      propsParams.refundQtyDesc = this.refundQtyDesc

      const params = {
        audit: {
          action: 'START'
        },
        bizDataJson: JSON.stringify(propsParams)
      }

      try {
        const result = await orderRefundApplyFlow(params)

        this.$emit('next', result)
        this.submitLoading = false
      } catch (e) {
        this.$message.error(e.message)
        this.submitLoading = false
      }
    }
  },
  filters: {
    rate: (value) => {
      return `${(Math.floor(value * 100) / 100 || 0)}%`
    }
  }
}
</script>

<style lang="less" scoped>

</style>
