<template>
  <div>
    <div class="mod-group">
      <div class="cell-header">
        <h4>退票申请结果</h4>
      </div>
      <div class="cell-body">
        <a-descriptions
          class="refund-card-desc"
          :column="2"
          layout="horizontal"
        >
          <a-descriptions-item label="退票单号">
            {{ result }}
          </a-descriptions-item>
          <a-descriptions-item label="退票状态">
            已提交
          </a-descriptions-item>
          <a-descriptions-item label="退票类型">
            {{ params && params.refundTypeCn }}
          </a-descriptions-item>
          <a-descriptions-item label="申请时间">
            {{ applyTime }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="refund-card">
          <a-icon class="refund-card-icon" type="check-circle"></a-icon>
          <div class="refund-card-text">退票申请成功</div>
        </div>
      </div>
    </div>
    <div class="mod-group">
      <div class="cell-body">
        <div class="refund-action-row">
          <a-button @click="handleBack"> 返回订单详情 </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    params: Object,
    order: Object,
    result: [Object, String]
  },
  data () {
    return {
      refundNo: '',
      applyTime: moment().format('YYYY-MM-DD')
    }
  },
  created () {
    this.applyTime = moment().format('YYYY-MM-DD')
  },
  methods: {
    handlePrev () {
      this.$emit('prev')
    },
    handleNext () {
      this.$emit('next')
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.refund-card-desc {
  width: 400px;
  margin: 0 auto;
}

.refund-card {
  margin: 0 auto;
  width: 250px;
  text-align: center;
  height: 250px;
  padding-top: 50px;
}
.refund-card-icon {
  font-size: 100px;
  color: @joy-orange-color;
}
.refund-card-text {
  margin-top: 10px;
}
</style>
