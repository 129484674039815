export const refundTypeMap = {
    C: 'C',
    U: 'U',
    UU: 'U'
}

export const refundTypeCnMap = {
    C: '自愿退票',
    U: '非自愿退票',
    UU: '病退'
}