<template>
    <div>
        <div class="mod-group">
        <div class="cell-header">
          <h4>选择退票旅客</h4>
        </div>
        <div class="cell-body">
          <a-form>
            <a-form-item label="退票类型">
              <a-radio-group v-model="refundType">
                <a-radio value="C">自愿退票</a-radio>
                <a-radio value="U">非自愿退票</a-radio>
                <a-radio value="UU">病退</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-row :gutter="20">
              <a-col span="12">
                <a-form-item label="备注" required>
                  <a-input type="textarea" v-model="remark"> </a-input>
                </a-form-item>
              </a-col>
              <a-col span="12">
                <a-form-item label="附件材料">
                  <a-upload
                    name="file"
                    :multiple="true"
                    action="/api/upload/normal"
                    :headers="{
                      token
                    }"
                    :fileList="fileList"
                    @change="onUpload"
                    :remove="removeFile"
                  >
                    <a-button> <a-icon type="upload" />上传文件</a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="20">
                申请病退所需材料要求<br/>
                1、旅客办理病退时，需携带县级(含)以上医疗单位(或相当于该级别的医疗机构，境外指诊所、 医疗中心以外的医院)出具的有主治医生签字的正规诊断证明盖章原件、病历、医院电脑打印的 挂号费发票及医药费收费单复印件，同时医药费金额不低于 100 元。
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
      <div class="mod-group">
        <div class="cell-header">
          <h4>选择退票旅客航段</h4>
        </div>
        <div class="cell-body">
          <div :style="{color: 'red'}">
            如您在团队网为旅客购买了保险，请到保险订单页面进行退保操作。
          </div>
          <div class="refund-segment-header"><a-checkbox :checked="segAllCheck" @click="handleSegAllClick()">全选航段</a-checkbox>
</div>
          <a-table
            :columns="refendSegColumns"
            :data-source="refendSegData"
            :rowKey="(record,index)=>{return index}"
            :pagination="false"
            :loading="loading"
            bordered
          >
            <template slot="index" slot-scope="index, row, idx">
              {{ idx + 1 }}
            </template>
            <template slot="segments" slot-scope="segments, row">
              <div v-for="(segment, index) in segments" :key="index">
                <a-checkbox
                  :checked="segment.checked"
                  :disabled="row.ticketStatus === 'REFUNDED'"
                  @click="handleSegClick(segment, row)">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}
                </a-checkbox>
              </div>
            </template>
          </a-table>
        </div>
      </div>
      <div class="mod-group">
        <div class="cell-header">
          <h4>航空客票退票规定</h4>
        </div>
        <div class="cell-body">
          <div class="refund-notice">
          <p><strong>团队票退票规定</strong></p>
          <p>1. 整团旅客自愿退票规定</p>
          <p>1) 全部未使用的团队(含单段、多段及往返团队)，退票费计算首先分别判断相应航段折扣，再</p>
          <p>按照第一航段航班时间确定退票费率。五折以上:团体旅客自愿退票在航班规定离站时间前 48 小时提出，收取票面价 50%的退票费;在航班规定离站时间 48 小时(含)内及航班规定截 载时间后提出，收取 100%退票费。五折(含)以下，收取票面价 100%的退票费;</p>
          <p>2) 若多段及往返团队使用其中一段后，其余段提出申退，收取票面价 100%的退票费。</p>
          <p>2. 部分旅客自愿退票规定</p>
          <p>1) 如团队剩余乘机人数少于原团规定的最少成团人数或少于整团人数 50%时，则不允许退票;</p>
          <p>2) 如团队剩余乘机人数不少于原团规定的最少成团人数，且不少于整团人数 50%时，按以下规 定办理:退票旅客如客票行程全部未使用按上述 1-(1)项团队退票规定办理;如退票旅客 部分行程未使用(即来回程、联程、缺口程退单程的情况)，按上述 1-(2)项团队退票规定办理。</p>
          <p>3. 旅客因病退票票务规定</p>
          <p>1) 定义:</p>
          <p>因病退票:旅客本人由于身体生病原因，无法按期乘坐航班，同时需要做退票处理。在有相关医 院证明材料的条件下，旅客本人退票不收取退票手续费。患病旅客的陪伴人员要求退票，应于航 班起飞前提出退票申请，并与患病旅客同时办理退票手续，陪同人员与患病旅客订票需在同一 PNR 里，且陪伴人员限额 2 名，可免收退票费。</p>
          <p>2) 退票原则:</p>
          <p>要求旅客在原出票地进行退票。旅客在代理人处进行病退时，代理人要将所需病退材料进行审核，无误后附在行程单后面一并交给结算中心。</p>
          <p>3) 所需材料:</p>
          <p>旅客办理病退时，需携带县级(含)以上医疗单位(或相当于该级别的医疗机构，境外指诊所、 医疗中心以外的医院)出具的有主治医生签字的正规诊断证明盖章原件、病历、医院电脑打印的 挂号费发票及医药费收费单复印件，同时医药费金额不低于 100 元。</p>
          <p>4) 病退要求:</p>
          <p>1. 旅客办理病退时，需携带上述所需材料，同时必须在航班起飞前取消 PNR 订座，回原出票地作全退处理。</p>
          <p>2. 旅客需提供乘机人证件、诊断证明、病历、医药收费单原件或彩色扫描件，由客票原出票地收集 所需所有材料后，交财务进行审核退款。</p>
          <p>3. 旅客如因病情突然发生，或在航班经停站临时发生病情，一时无法取得医疗单位证明的，或因病 情不适宜乘机而被机场值机拒绝其登机的，必须由值机部门负责人和场站代表在行程单或证明材料上签字或盖章。此类情况下，不论是否在航班起飞前取消座位，均可以作全退处理。 </p>
          <p>4. 如遇以上未提及的特殊病退情况，需单独请示病退处理流程。</p>
          <p>5) 处罚:</p>
          <p>如果代理人伪造病退材料，冒充病退，我司将对代理人处以该客票航段 Y 舱公布运价 3 倍的罚金。</p>
          </div>
        </div>
      </div>
      <div class="mod-group">
        <div class="cell-body">
          <a-checkbox v-model="agree">我已阅读航空退票规定</a-checkbox>
          <div class="refund-action-row">
              <a-button @click="handlePrev">
                返回
              </a-button>
              <a-button type="primary" :style="{ marginLeft: '8px' }" @click="handleNext" :loading="submitLoading">
                下一步
              </a-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { orderRefundCheck } from '@/api/order'
import { refundTypeMap, refundTypeCnMap } from '@/dicts/refund'

import _ from 'lodash'

const refendSegColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '姓名',
    dataIndex: 'passengerName'
  },
  {
    title: '票号',
    dataIndex: 'ticketNo'
  },
  {
    title: '航班状态',
    dataIndex: 'ticketStatus'
  },
  {
    title: 'PNR编码',
    dataIndex: 'pnr'
  },
  {
    title: '选择退票航段',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  }
]

export default {
  props: {
    order: Object,
    active: Boolean
  },
  data () {
    return {
      refundType: 'C',
      token: this.$ls.get('Access-Token'),
      fileList: [],
      remark: '',
      agree: false,
      segAllCheck: false,
      refendSegColumns,
      refendSegData: [],
      submitLoading: false
    }
  },
  computed: {
    loading () {
      return !this.order.orderId
    },
    orderId () {
      return this.order && this.order.orderId
    }
  },
  watch: {
    order (order) {
      this.freshRefundSegments()
    }
  },
  created () {
    if (this.order && this.order.orderId) {
      this.freshRefundSegments()
    }
  },
  methods: {
    freshRefundSegments () {
      const { airPassengerSegmentDTOS } = this.order
      this.refendSegData = airPassengerSegmentDTOS.map((item) => {
        return {
          ...item,
          ...{
            segments: item.segmentInfos.map((ele) => {
              return {
                ...ele,
                ...{ checked: false }
              }
            })
          }
        }
      })
    },
    handleSegClick (seg, row) {
      seg.checked = !seg.checked

      // 往返程同时勾选
      // this.refendSegData.forEach((item) => {
      //   if (row.ticketNo === item.ticketNo && row.passengerName === item.passengerName) {
      //     item.segments.forEach((inSeg) => {
      //       inSeg.checked = seg.checked
      //     })
      //   }
      // })

      let noCheckedCount = 0
      this.refendSegData.forEach((item) => {
        item.segments.forEach((seg) => {
          !seg.checked && noCheckedCount++
        })
      })
      this.segAllCheck = noCheckedCount === 0
    },
    handleSegAllClick () {
      this.refendSegData.forEach((item) => {
        item.segments.forEach((seg) => {
          if (item.ticketStatus !== 'REFUNDED') {
            seg.checked = !this.segAllCheck
          }
        })
      })
      this.segAllCheck = !this.segAllCheck
    },
    handleUpload () {},
    removeFile () {
      this.fileList = []
      return true
    },
    validateRefundCheck (dtos) { // 判断退票人选择的航段是否一致
      const group = _.groupBy(dtos, (item) => {
        item.segmentString = item.segments[0].depCode + item.segments[0].arrCode
        return item.passengerName
      })

      const groupSegMap = {}

      for (const key in group) {
        groupSegMap[key] = group[key].map((item) => { return item.segmentString }).join('')
      }

      const groupSegValues = _.values(groupSegMap)
      const uniqResult = _.uniq(groupSegValues)

      if (uniqResult.length > 1) {
        this.$message.error('旅客所选退票航段不一样，请重新选择')
        return false
      } else {
        return true
      }
    },
    async submitRefundCheck () {
      let DTOs = this.refendSegData.map((item) => {
        if (item.segments.filter((item) => (item.checked)).length) {
          return {
            detailId: item.detailId,
            orderId: item.orderId,
            orderItemId: item.orderItemId,
            orderItemServiceId: item.serviceId
          }
        } else {
          return null
        }
      })

      let rawDTOs = this.refendSegData.map((item) => {
        if (item.segments.filter((item) => (item.checked)).length) {
          return item
        } else {
          return null
        }
      })

      DTOs = DTOs.filter((item) => { return item !== null })
      rawDTOs = rawDTOs.filter((item) => { return item !== null })

      if (!this.remark) {
        this.$message.error('请填写备注信息')
        return
      }

      if (!this.fileList.length && this.refundType === 'UU') {
        this.$message.error('请先上传材料')
        return
      }

      if (!DTOs.length) {
        this.$message.error('请先选择要退票的航段')
        return
      }

      if (!this.validateRefundCheck(rawDTOs)) {
        return
      }

      if (!this.agree) {
        this.$message.error('请先阅读并同意退票协议')
        return
      }

      this.submitLoading = true

      const { fileList } = this
      const params = {
        // autoFPass: true,
        descp: this.remark,
        detailDTOList: DTOs,
        orderId: this.orderId,
        refundType: refundTypeMap[this.refundType],
        refundTypeCn: refundTypeCnMap[this.refundType]
      }

      if (fileList.length) {
        params.uploadFileDTOS = fileList.map(item => {
          return {
            oriFileName: item.name,
            attachFilePath: item.filePath
          }
        })
      }

      try {
        const result = await orderRefundCheck(params)
        this.submitLoading = false
        if (result) {
          this.$emit('valid', params) // 吐出查询参数，退票后几部都是这个参数
          this.$emit('next')
        }
      } catch (e) {
        this.submitLoading = false
        this.$message.error(e.message)
      }
    },
    handlePrev () {
      this.$emit('prev')
    },
    handleNext () {
      this.submitRefundCheck()
    },
    onUpload (info) {
      let { fileList } = info

      if (fileList.length) {
        fileList = fileList.map(file => {
          if (file.response) {
            const { status, data } = file.response

            if (status === 'success') {
              file.name = data.fileName
              file.filePath = data.filePath
            } else {
              file.status = 'fail'
            }
          }

          return file
        })

        this.fileList = fileList
      }
    }
  }
}
</script>

<style lang="less" scoped>
.refund-segment-header {
  text-align: right;
  margin-bottom: 10px;
}

.refund-notice {
  font-size: 12px;
  height: 250px;
  overflow-y: scroll;

  &.flow {
    height: auto;
    overflow-y: auto;
  }

  p {
    margin-bottom: 0;
  }
}

</style>
